<template>
    <div class="GenerateMandateForm">
        <v-form @submit.prevent="generateMandat">
            <v-card class="GenerateMandateForm-card">
                <v-card-text>
                    <template
                        v-for="field in selectedTemplate"
                        :key="field.id"
                    >
                        <v-text-field
                            v-if="field.type === 'text'"
                            v-model="formValues[field.id]"
                            :error-messages="v$[field.id]?.$errors[0]?.$message ? $t(v$[field.id].$errors[0].$message) : ''"
                            :label="$t(field.label)"
                            :disabled="field.readOnly"
                            variant="underlined"
                            @update:model-value="updateModelValue(field)"
                        />
                        <v-autocomplete
                            v-if="field.type === 'choice'"
                            v-model="formValues[field.id]"
                            :error-messages="v$[field.id]?.$errors[0]?.$message ? $t(v$[field.id].$errors[0].$message) : ''"
                            :items="field.params.list"
                            :label="$t(field.label)"
                            :item-title="field.id === 'city' ? 'name' : 'label'"
                            :item-value="field.id === 'city' ? 'id' : 'value'"
                            :disabled="field.readOnly"
                            variant="underlined"
                            @update:model-value="updateModelValue(field)"
                        />
                    </template>
                    <v-card-actions class="justify-center">
                        <v-btn
                            class="GenerateMandateForm-submitButton bg-primary"
                            type="submit"
                        >
                            {{ $t('iban:submit_button:label') }}
                        </v-btn>
                    </v-card-actions>
                </v-card-text>
            </v-card>
        </v-form>
    </div>
</template>

<script setup>
import { computed, onMounted, reactive, ref } from 'vue'
import { regex, required } from '@/StoreWeb/utils/i18n-validators'
import { helpers } from '@vuelidate/validators'
import { useStore } from 'vuex'
import * as paymentActionTypes from '@/StoreWeb/store/modules/payment/action-types'
import { useVuelidate } from '@vuelidate/core'
import * as addressActionTypes from '@/StoreWeb/store/modules/address/action-types'
import { isEmpty } from '@/StoreWeb/utils/global-utils'
import emitter from '@/StoreWeb/utils/global-emitter'
import { useI18n } from 'vue-i18n'
import {
    initFieldsDependenciesFromTemplate,
    browseAndApplyDependenciesSchemas
} from '@/StoreWeb/js/composables/fields-dependencies-from-template-utils'
import { useLogin } from '@/StoreWeb/js/composables/login-utils'

const props = defineProps({
    provider: {
        type: Object,
        required: true
    }
})

const store = useStore()
const { t } = useI18n()
const { userInfos } = useLogin()

const addBankAccountForm = ref()
const selectedTemplate = ref()
const getLabel = ref('')
const getSubtitle = ref('')

const addresses = computed(() => store.getters.getAddresses)
const defaultAddress = computed(() => {
    if (!isEmpty(addresses.value)) {
        return addresses.value.find(address => address.defaultAddress) || addresses.value[0]
    }
    return null
})

const formValues = computed(() => {
    const fields = {}
    selectedTemplate.value?.forEach(field => {
        const addressInfo = defaultAddress.value
        fields[field.id] = !isEmpty(addressInfo) && field.id in addressInfo ? addressInfo[field.id] : (field.value ?? '')
        if (field.id === 'firstName') {
            fields[field.id] = addressInfo?.receiverFirstname ?? userInfos.value.firstName
        } else if (field.id === 'lastName') {
            fields[field.id] = addressInfo?.receiverLastname ?? userInfos.value.lastName
        }
    })
    return reactive(fields)
})

const generateMandateRules = computed(() => {
    return selectedTemplate.value?.reduce((acc, field) => {
        acc[field.id] = {}
        if (field.required) acc[field.id].required = required
        if (field.params?.regex) {
            // Création d'une fonction qui retourne le message d'erreur souhaité
            const getErrorMsg = () => {
                const errorMsg = field.params.regexValidationMessage
                    ? t(field.params.regexValidationMessage)
                    : t('validations:regex')
                return errorMsg
            }
            // Application de ce message à la règle regex
            acc[field.id].regex = helpers.withMessage(getErrorMsg, regex(new RegExp(field.params.regex)))
        }
        return acc
    }, {})
})

async function getAddressTemplate () {
    const templateUrl = `${process.env.VUE_APP_CDN_URL}${process.env.VUE_APP_NETWORK_ID}/template/template_${props.provider.id}_bankAccount.json`
    const response = await fetch(templateUrl)
    if (!response.ok) {
        emitter.emit('showAlertModal', {
            messageHTML: t(`address:get_address_templates:error:${response.status}:message`)
        })
    } else {
        try {
            addBankAccountForm.value = await response.json()
            selectedTemplate.value = addBankAccountForm.value?.fields
            getLabel.value = addBankAccountForm.value?.label
            getSubtitle.value = addBankAccountForm.value?.subtitle
        } catch (error) {
            emitter.emit('showAlertModal', {
                messageHTML: t('address:get_address_templates:error:parse:message')
            })
        }
    }
}

async function generateMandat () {
    v$.value.$touch()
    const isValid = await v$.value.$validate()
    if (!v$.value.$invalid && isValid) {
        const transformedObject = transformObject(formValues.value)
        await store.dispatch(paymentActionTypes.CREATE_BANK_ACCOUNT, {
            data: transformedObject,
            providerId: props.provider.id,
            getBankAccount: false
        }).then((response) => {
            if (response.mandateAttachment) {
                const pdfBase64 = response.mandateAttachment
                const pdfBlob = base64ToBlob(pdfBase64, 'application/pdf')
                const pdfUrl = URL.createObjectURL(pdfBlob)
                window.open(pdfUrl, '_blank')
            }
        })
    }
}

function base64ToBlob (base64, mimeType) {
    const byteCharacters = atob(base64)
    const byteNumbers = new Array(byteCharacters.length)

    for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i)
    }

    const byteArray = new Uint8Array(byteNumbers)
    return new Blob([byteArray], { type: mimeType })
}

function getSanitizedAddress (originalObj) {
    return [
        originalObj.streetNumber,
        originalObj.streetNumberSuffix,
        originalObj.streetName,
        originalObj.streetLine2
    ]
        .filter(Boolean)
        .join(' ')
}

function transformObject (originalObj) {
    return {
        firstName: originalObj.firstName,
        lastName: originalObj.lastName,
        address: getSanitizedAddress(originalObj),
        zipCode: originalObj.zipCode,
        city: originalObj.city,
        country: originalObj.country,
        bankName: originalObj.bankName,
        iban: originalObj.iban,
        bic: originalObj.bic,
        providerBankAccountIdentifier: originalObj.providerBankAccountIdentifier
    }
}

function updateModelValue (field) {
    v$.value[field.id]?.$touch()
    browseAndApplyDependenciesSchemas({
        formFieldValues: formValues.value,
        field,
        vuelidate: v$.value
    })
}

onMounted(async () => {
    await getAddressTemplate()
    await store.dispatch(addressActionTypes.GET_ADDRESSES)
    initFieldsDependenciesFromTemplate({
        formFieldValues: formValues.value,
        template: selectedTemplate.value,
        vuelidate: v$.value
    })
})

const v$ = useVuelidate(generateMandateRules, formValues)
</script>

<style lang="scss" scoped>
@import 'globalScss';

.GenerateMandateForm {
    &-card {
        border-radius: 6px;
        box-shadow: $box-shadow-light;
        background: $color-lightest;
    }
}
</style>
