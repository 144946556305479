<template>
    <div class="AddressEditModal">
        <modal
            v-model="showModal"
            :title="$t('address:edit:title')"
        >
            <alert-message
                v-if="error"
                :message="$t('address:updated_error')"
                type="info"
                class="AddressSelector-emptyAddressList"
            />
            <div class="AddressEditModal-body">
                <address-template-selector
                    :defaultTemplateCode="'USER'"
                    :templateList="getTemplateList"
                    class="AddressEditModal-templateSelector"
                    @template-changed="updateSelectedTemplate"
                />
                <v-form @submit.prevent="updateAddress">
                    <template
                        v-for="field in templateWithValues.fields"
                    >
                        <v-text-field
                            v-if="field.type === 'text'"
                            :key="field.id"
                            v-model="formFields[field.id]"
                            :label="field.label"
                            :readonly="field.readonly"
                            :required="field.required"
                            items-text="name"
                            item-value="id"
                            :error-messages="v$[field.id].$errors.map(e => e.$message)"
                            class="mb-2"
                            variant="underlined"
                            @update:model-value="updateModelValue(field)"
                        />
                        <v-autocomplete
                            v-if="field.type === 'choice'"
                            :key="field.id"
                            v-model="formFields[field.id]"
                            variant="underlined"
                            :items="field.params.list"
                            item-title="name"
                            item-value="id"
                            :label="field.label"
                            :readonly="field.readonly"
                            :required="field.required"
                            :no-data-text="$t(`address:${field.id}:no-data`)"
                            :error-messages="v$[field.id].$errors.map(e => e.$message)"
                            @update:model-value="updateModelValue(field)"
                        />
                    </template>
                </v-form>
            </div>
            <template #actions>
                <v-btn
                    variant="text"
                    @click="closeModal"
                >
                    {{ $t('button:cancel') }}
                </v-btn>
                <v-btn
                    class="bg-primary ml-2"
                    @click="submitForm"
                >
                    {{ $t('button:edit') }}
                </v-btn>
            </template>
        </modal>
    </div>
</template>

<script setup>
import AddressManager from '@/StoreWeb/managers/AddressManagerSingleton'
import AddressTemplateSelector from '@/StoreWeb/components/address/AddressTemplateSelector'
import { isEmpty } from 'global-utils'
import * as addressActionTypes from '@/StoreWeb/store/modules/address/action-types'
import AlertMessage from '@/StoreWeb/components/common/AlertMessage.vue'
import { computed, onBeforeMount, reactive, ref } from 'vue'
import { useStore } from 'vuex'
import { numeric, regex, required } from '@/StoreWeb/utils/i18n-validators'
import { useI18n } from 'vue-i18n'
import { helpers } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
import {
    initFieldsDependenciesFromTemplate,
    browseAndApplyDependenciesSchemas
} from '@/StoreWeb/js/composables/fields-dependencies-from-template-utils'
import cloneDeep from 'lodash.clonedeep'

const emit = defineEmits(['update:modelValue'])

const props = defineProps({
    address: {
        type: Object,
        required: true
    },
    modelValue: {
        type: Boolean,
        required: true
    }
})

const store = useStore()
const { t } = useI18n()

const addressManager = reactive(AddressManager.getInstance())
const templateCode = ref('USER')
const error = ref(false)
const templateWithValues = ref(null)

const showModal = computed({
    get: () => props.modelValue,
    set: (newValue) => {
        emit('update:modelValue', newValue)
    }

})

const getAddressTemplates = computed(() => store.getters.getAddressTemplates)

const getFormParams = computed(() => {
    return {
        id: props.address.id,
        template: getTemplate.value
    }
})

const getTemplate = computed(() => getAddressTemplates.value.find(item => item.code === templateCode.value))

const getTemplateList = computed(() => {
    const templates = []
    getAddressTemplates.value.forEach(template => {
        if (template.minNb > 0 && template.maxNb > 0) {
            templates.push({
                id: template.code,
                label: template.label
            })
        }
    })
    return templates
})

const formFields = computed(() => {
    const fields = {}

    if (!isEmpty(templateWithValues.value) && !isEmpty(templateWithValues.value.fields)) {
        templateWithValues.value.fields.forEach(field => {
            fields[field.id] = field.value
        })
    }
    return reactive(fields)
})

const rules = computed(() => {
    const rules = {}
    const template = getTemplate
    if (!isEmpty(template.value) && !isEmpty(template.value.fields)) {
        template.value.fields.forEach(field => {
            rules[field.id] = {}
            if (field.required) {
                rules[field.id].required = required
            }
            if (field.params.pattern) {
                rules[field.id].regexCustom = helpers.withMessage(
                    () => {
                        const transKey = field.params.patternValidationMessage
                        let errorMsg = transKey ? t(transKey) : ''
                        if (isEmpty(errorMsg)) {
                            errorMsg = t('validations:regex')
                        }
                        return errorMsg
                    },
                    regex(new RegExp(field.params.pattern))
                )
            }
            if (field.params.subType === 'number') {
                rules[field.id].numeric = numeric
            }
            if (!isEmpty(field.params.list) && !isEmpty(field.value)) {
                const list = field.params.list.map(element => element.id)
                const contains = (value) => list.includes(value)
                const errorMessage = t('validations.mustBeInList')
                rules[field.id].mustBeInList = helpers.withMessage(errorMessage, contains)
            }
        })
    }
    return rules
})

const v$ = useVuelidate(rules, formFields, { $scope: 'edit' })

onBeforeMount(() => {
    initTemplateWithValue()
    initFieldsDependenciesFromTemplate({
        formFieldValues: formFields.value,
        template: templateWithValues.value.fields,
        vuelidate: v$.value
    })
})

function updateModelValue (field) {
    v$.value[field.id].$touch()
    browseAndApplyDependenciesSchemas({
        formFieldValues: formFields.value,
        field,
        vuelidate: v$.value
    })
}

function initTemplateWithValue () {
    const templateToUse = cloneDeep(getTemplate.value)
    if (!isEmpty(props.address)) {
        templateWithValues.value = props.address.getTemplateWithValues(templateToUse)
    }
    templateWithValues.value = templateToUse
}

function closeModal () {
    showModal.value = false
}

async function submitForm () {
    v$.value.$touch()
    const isValid = await v$.value.$validate()
    if (!v$.value.$invalid && isValid) {
        updateAddress()
    }
}

function updateAddress () {
    const data = {
        values: { ...formFields.value, ...{ defaultAddress: props.address.defaultAddress } },
        id: getFormParams.value.id,
        template: getFormParams.value.template
    }
    addressManager.updateAddressAction({
        data: data.values,
        id: data.id,
        template: data.template
    })
        .then(() => {
            store.dispatch(addressActionTypes.GET_ADDRESSES, {
                cachiosOptions: {
                    force: true
                }
            })
            error.value = false
            closeModal()
        })
        .catch(() => {
            error.value = true
            closeModal()
        })
}

function updateSelectedTemplate (value) {
    templateCode.value = value
}
</script>

<style lang="scss" scoped>
@import 'globalScss';

.AddressEditModal {
    &-templateSelector {
        margin-bottom: 20px;
    }
}
</style>
