import * as actionTypes from '@/StoreWeb/store/modules/application-session/action-types'
import * as mutationTypes from '@/StoreWeb/store/modules/application-session/mutation-types'
import * as mainMutationTypes from '@/StoreWeb/store/modules/main/mutation-types'
import cachios from 'cachios'
import config from 'config'
import DebuggingManager from '@/StoreWeb/managers/DebuggingManagerSingleton'
import ApplicationSessionNormalizer from '@/StoreWeb/normalizers/application-session/ApplicationSessionNormalizerSingleton'
import ApplicationSessionSerializer from '@/StoreWeb/serializers/application-session/ApplicationSessionSerializerSingleton'
import { isEmpty } from 'global-utils'
import i18n from 'i18n'
import ErrorManager from '@/StoreWeb/managers/ErrorManager'

export default class {
    constructor () {
        this._activeToken = null
        this._applicationSessionNormalizer = ApplicationSessionNormalizer.getInstance()
        this._applicationSessionSerializer = ApplicationSessionSerializer.getInstance()
        this._debuggingManager = DebuggingManager.getInstance()
        this._store = null
    }

    get applicationSessionNormalizer () {
        return this._applicationSessionNormalizer
    }

    get applicationSessionSerializer () {
        return this._applicationSessionSerializer
    }

    get activeToken () {
        return this._activeToken
    }

    set activeToken (activeToken) {
        this._activeToken = activeToken
    }

    get debuggingManager () {
        return this._debuggingManager
    }

    get store () {
        return this._store
    }

    set store (store) {
        this._store = store
    }

    activateToken () {
        cachios.axiosInstance.defaults.headers.common.Authorization = this.activeToken
    }

    deactivateToken () {
        this.activeToken = cachios.axiosInstance.defaults.headers.common.Authorization
        delete cachios.axiosInstance.defaults.headers.common.Authorization
    }

    getIsNfcSupported (state) {
        return state.applicationSession !== null &&
            !isEmpty(state.applicationSession.data) &&
            state.applicationSession.data.isNfcSupported
    }

    /* =============================================================================================================
    =================================================== ACTIONS ====================================================
    ============================================================================================================= */
    createApplicationSessionAction (params) {
        return new Promise((resolve, reject) => {
            this.store.commit(mainMutationTypes.SET_SHOW_GLOBAL_LOADER, { value: true })
            this.store.commit(mutationTypes.SET_APPLICATION_SESSION_PENDING_REQUEST, { key: 'createApplicationSession', value: true })

            let session = this.store.state.applicationSessionModule.applicationSession.toJSON()
            if (!isEmpty(params) && !isEmpty(params.session)) {
                session = params.session
            }

            session = this.applicationSessionSerializer.serialize(session)

            this.deactivateToken()

            cachios
                .post(`${config.storeWebMiddlewareUrl}/application-session/create`, session, {
                    force: true
                })
                .then(response => {
                    const normalizedSession = this.applicationSessionNormalizer.normalize(response.data)
                    this.store.commit(mutationTypes.SET_APPLICATION_SESSION, normalizedSession)
                    this.store.commit(mutationTypes.SET_APPLICATION_SESSION_PENDING_REQUEST, { key: 'createApplicationSession', value: false })
                    this.store.commit(mainMutationTypes.SET_SHOW_GLOBAL_LOADER, { value: false })
                    resolve(normalizedSession)
                })
                .catch(error => {
                    if (!isEmpty(params) && params.isRetryAfterUpdate) {
                        ErrorManager.displayErrorModal('application_session:update_session', params.updateError ?? error)
                    } else {
                        ErrorManager.displayErrorModal('application_session:create_session', error)
                    }
                    this.store.commit(mutationTypes.SET_APPLICATION_SESSION_PENDING_REQUEST, { key: 'createApplicationSession', value: false })
                    this.store.commit(mainMutationTypes.SET_SHOW_GLOBAL_LOADER, { value: false })
                    reject(error)
                })

            if (!isEmpty(this.activeToken)) {
                this.activateToken()
            }
        })
    }

    getApplicationSessionAction (params) {
        return new Promise((resolve, reject) => {
            this.store.commit(mainMutationTypes.SET_SHOW_GLOBAL_LOADER, { value: true })
            this.store.commit(mutationTypes.SET_APPLICATION_SESSION_PENDING_REQUEST, { key: 'getApplicationSession', value: true })

            if (this.debuggingManager.getMockActionState('getApplicationSession')) {
                this.debuggingManager.getResponse('getApplicationSession', (response) => {
                    const applicationSession = response.data
                    if (
                        !isEmpty(applicationSession) &&
                        !isEmpty(applicationSession.data) &&
                        !isEmpty(applicationSession.data.userToken)
                    ) {
                        let userToken
                        if (applicationSession.data.userToken.indexOf('accessToken') !== -1) {
                            userToken = JSON.parse(applicationSession.data.userToken)
                            userToken.expirationDate = Math.floor(new Date().getTime() / 1000) + 24 * 60 * 60
                            applicationSession.data.userToken = JSON.stringify(userToken)
                        } else { // If the token is using the old structure instead of a stringified object
                            userToken = {
                                accessToken: applicationSession.data.userToken,
                                expirationDate: applicationSession.data.expirationDate,
                                refreshToken: applicationSession.data.refreshToken
                            }
                            userToken.expirationDate = Math.floor(new Date().getTime() / 1000) + 24 * 60 * 60
                            applicationSession.data.userToken = JSON.stringify(userToken)
                            delete applicationSession.data.expirationDate
                            delete applicationSession.data.refreshToken
                        }
                        this.debuggingManager.simulateAuthenticatedUser()
                    }

                    const normalizedSession = this.applicationSessionNormalizer.normalize(applicationSession)
                    this.store.commit(mutationTypes.SET_APPLICATION_SESSION, normalizedSession)

                    this.store.commit(mutationTypes.SET_APPLICATION_SESSION_PENDING_REQUEST, { key: 'getApplicationSession', value: false })
                    this.store.commit(mainMutationTypes.SET_SHOW_GLOBAL_LOADER, { value: false })

                    resolve(normalizedSession)
                })
            } else {
                this.deactivateToken()

                cachios
                    .get(`${config.storeWebMiddlewareUrl}/application-session/read/${params.id}`, {
                        force: true
                    })
                    .then(response => {
                        const normalizedSession = this.applicationSessionNormalizer.normalize(response.data)
                        this.store.commit(mutationTypes.SET_APPLICATION_SESSION, normalizedSession)

                        this.store.commit(mutationTypes.SET_APPLICATION_SESSION_PENDING_REQUEST, { key: 'getApplicationSession', value: false })
                        this.store.commit(mainMutationTypes.SET_SHOW_GLOBAL_LOADER, false)

                        resolve(normalizedSession)
                    })
                    .catch(async error => {
                        if (error?.response?.status === 404) {
                            localStorage.removeItem('applicationSessionId')
                            const applicationSession = await this.store.dispatch(actionTypes.CREATE_APPLICATION_SESSION)

                            this.store.commit(mutationTypes.SET_APPLICATION_SESSION_PENDING_REQUEST, { key: 'getApplicationSession', value: false })
                            this.store.commit(mainMutationTypes.SET_SHOW_GLOBAL_LOADER, { value: false })

                            if (applicationSession?.id) {
                                resolve(applicationSession)
                            } else {
                                const error = new Error(i18n.global.t('application_session:get_session:error:default:message'))
                                ErrorManager.displayErrorModal('application_session:get_session', error)
                                reject(error)
                            }
                        } else {
                            ErrorManager.displayErrorModal('application_session:get_session', error)

                            this.store.commit(mutationTypes.SET_APPLICATION_SESSION_PENDING_REQUEST, { key: 'getApplicationSession', value: false })
                            this.store.commit(mainMutationTypes.SET_SHOW_GLOBAL_LOADER, { value: false })

                            reject(error)
                        }
                    })

                if (!isEmpty(this.activeToken)) {
                    this.activateToken()
                }
            }
        })
    }

    updateApplicationSessionAction (params) {
        return new Promise((resolve, reject) => {
            this.store.commit(mainMutationTypes.SET_SHOW_GLOBAL_LOADER, { value: true })
            this.store.commit(mutationTypes.SET_APPLICATION_SESSION_PENDING_REQUEST, { key: 'updateApplicationSession', value: true })

            let session = this.store.state.applicationSessionModule.applicationSession.toJSON()

            if (!isEmpty(params) && !isEmpty(params.session)) {
                session = params.session
            }

            session = this.applicationSessionSerializer.serialize(session)

            if (session.id) {
                this.deactivateToken()

                cachios
                    .put(`${config.storeWebMiddlewareUrl}/application-session/update/${session.id}`, session, {
                        force: true
                    })
                    .then(response => {
                        const normalizedSession = this.applicationSessionNormalizer.normalize(response.data)
                        this.store.commit(mutationTypes.SET_APPLICATION_SESSION, normalizedSession)
                        this.store.commit(mutationTypes.SET_APPLICATION_SESSION_PENDING_REQUEST, { key: 'updateApplicationSession', value: false })
                        this.store.commit(mainMutationTypes.SET_SHOW_GLOBAL_LOADER, { value: false })

                        resolve(normalizedSession)
                    })
                    .catch(async error => {
                        this.store.commit(mutationTypes.SET_APPLICATION_SESSION_PENDING_REQUEST, { key: 'updateApplicationSession', value: false })
                        this.store.commit(mainMutationTypes.SET_SHOW_GLOBAL_LOADER, { value: false })

                        if (error?.response?.status === 404) {
                            const session = this.store.state.applicationSessionModule.applicationSession
                            session.id = null
                            this.store.commit(mutationTypes.SET_APPLICATION_SESSION, session)
                            await this.store.dispatch(actionTypes.CREATE_APPLICATION_SESSION, {
                                isRetryAfterUpdate: true,
                                updateError: error
                            })
                        } else {
                            ErrorManager.displayErrorModal('application_session:update_session', error)
                        }
                        reject(error)
                    })

                if (!isEmpty(this.activeToken)) {
                    this.activateToken()
                }
            } else {
                this.createApplicationSessionAction()
            }
        })
    }
}
