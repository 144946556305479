<template>
    <component
        :is="!isPartOfForm ? 'form' : 'div'"
        class="VuetifyDynamicForm"
    >
        <p
            v-if="showFormTitle && formTitle !== ''"
            :class="formTitleClass"
            class="VuetifyDynamicForm-title"
        >
            {{ formTitle }}
        </p>
        <div
            v-for="field in templateFields"
            :key="field.id"
            class="VuetifyDynamicForm-fieldWrapper"
        >
            <component
                :is="field.beforeAssetPlaceholder"
                v-if="field.beforeAssetPlaceholder"
            />
            <v-select
                v-if="field.vuetifyComponentName === 'v-select'"
                :id="field.id"
                v-model="formValues[field.id]"
                :autocomplete="field.autocomplete"
                :error-messages="$v[field.id]?.$errors[0]?.$message ? $t($v[field.id].$errors[0].$message) : ''"
                :hint="!isEmpty(field.description) ? t(field.description) : null"
                :items="field.options"
                :label="t(field.label)"
                :readonly="field.readonly"
                :required="field.required"
                :variant="variant ?? 'filled'"
                class="VuetifyDynamicForm-select"
                item-value="id"
                item-title="name"
                @update:model-value="onUpdateValue(field.id)"
            />
            <v-text-field
                v-if="field.vuetifyComponentName === 'v-text-field'"
                :id="field.id"
                v-model="formValues[field.id]"
                :autocomplete="field.autocomplete"
                :class="[
                    field.inputType === 'date' ? 'VuetifyDynamicForm-dateField' : 'VuetifyDynamicForm-textField',
                    field.readonly || field.disabled ? 'VuetifyDynamicForm-input--readonly' : ''
                ]"
                :clearable="!field.readonly"
                :error-messages="$v[field.id]?.$errors[0]?.$message ? $t($v[field.id].$errors[0].$message) : ''"
                :hide-details="'auto'"
                :hint="!isEmpty(field.description) ? t(field.description) : null"
                :label="t(field.label)"
                :max="field.max"
                :maxlength="field.maxLength ? field.maxLength : null"
                :min="field.min"
                :minlength="field.minLength ? field.minLength : null"
                :readonly="field.readonly"
                :required="field.required"
                :type="field.inputType"
                :variant="variant ?? 'underlined'"
                persistent-hint
                @update:model-value="onUpdateValue(field.id)"
            />
            <v-switch
                v-if="field.vuetifyComponentName === 'v-switch'"
                :id="field.id"
                v-model="formValues[field.id]"
                :error-messages="$v[field.id]?.$errors ? $v[field.id]?.$errors.map(e => e.$message) : ''"
                :label="t(field.label)"
                inset
                @update:model-value="onUpdateValue(field.id)"
            />
            <component
                :is="field.afterAssetPlaceholder"
                v-if="field.afterAssetPlaceholder"
            />
        </div>
        <div
            v-if="!isPartOfForm"
            class="VuetifyDynamicForm-buttonsArea"
        >
            <v-btn
                class="bg-primary"
                variant="flat"
                type="submit"
                size="large"
                @click.prevent="onSubmit"
            >
                {{ buttonLabel ? buttonLabel : t('button:validate') }}
            </v-btn>
        </div>
    </component>
</template>

<script setup>
import { computed, inject, onMounted, reactive } from 'vue'
import { isEmpty } from 'global-utils'
import { useI18n } from 'vue-i18n'
import { useVuelidate } from '@vuelidate/core'
import { getFormRules } from '@/StoreWeb/js/composables/forms-utils'

const { t } = useI18n()

const emit = defineEmits(['formSubmitted', 'formUpdated'])

const props = defineProps({
    buttonLabel: {
        type: String,
        default: ''
    },
    formId: {
        type: String,
        default: ''
    },
    formTitleClass: {
        type: String,
        default: ''
    },
    isPartOfForm: {
        type: Boolean,
        default: false
    },
    params: {
        type: Object,
        default: null
    },
    showFormTitle: {
        type: Boolean,
        default: true
    },
    template: {
        type: Object,
        required: true
    },
    variant: {
        type: String,
        default: null
    },
    isPreFilled: {
        type: Boolean,
        default: false
    }
})

const handleFormSubmitted = inject('handleFormSubmitted', () => {})

const formTitle = computed(() => {
    return !isEmpty(props.template.label) ? t(props.template.label) : ''
})
const formValues = computed(() => {
    const fields = {}

    templateFields.value?.forEach(field => {
        if (field.value) {
            fields[field.id] = field.value
        } else {
            fields[field.id] = ''
        }
    })

    return reactive(fields)
})
const templateFields = computed(() => formatFields(props.template.fields))

const formatFields = (fields) => {
    if (!isEmpty(fields)) {
        return fields
            .map(field => field.getConfig())
            .filter(fieldConfig => !isEmpty(fieldConfig) && !isEmpty(fieldConfig.componentName))
    }

    return []
}

const onSubmit = async () => {
    $v.value.$touch()
    const isValid = await $v.value.$validate()

    if (isValid) {
        const data = {
            isValid: true,
            values: formValues.value
        }

        if (props.formId) {
            data.formId = props.formId
        }
        emit('formSubmitted', data)
    }
}

const onUpdateValue = async (fieldId) => {
    $v.value[fieldId]?.$touch()
    if (props.isPartOfForm) {
        const isValid = !$v.value.$invalid
        const data = {
            isValid,
            values: formValues.value
        }

        if (props.formId) {
            data.formId = props.formId
        }

        emit('formUpdated', data)
    }
}

onMounted(() => {
    if (props.isPreFilled) {
        $v.value.$touch()
        const isValid = !$v.value.$invalid

        const data = {
            isValid,
            values: formValues.value
        }
        if (props.formId) {
            data.formId = props.formId
        }
        handleFormSubmitted(data)
        emit('formSubmitted', data)
    }
})

const rules = getFormRules(props.template)

const $v = useVuelidate(
    rules,
    formValues
)
</script>

<style scoped lang="scss">
@import 'globalScss';

.VuetifyDynamicForm {
    display: flex;
    flex-direction: column;
    gap: $s4;

    &-title {
        margin: 0;
    }

    &-dateField :deep(.v-field) {
        max-width: 170px;
    }

    &-dateField :deep(.v-field.v-field--variant-outlined) {
        max-width: 194px;
    }

    &-input--readonly,
    &-input--disabled {
        :deep(.v-field) {
            cursor: not-allowed;
            pointer-events: none;
        }

        :deep(.v-field:not(.v-field--variant-underlined)) {
            background-color: $color_lightgray1;
        }

        :deep(.v-field--variant-underlined) {
            color: rgba(red($input-foregroundColor), green($input-foregroundColor), blue($input-foregroundColor), .7);
        }
    }
}
</style>
