<template>
    <div
        :class="{ 'PhotoFileInputFieldWithLabel--format4-3' : formatRatio === '4:3', 'PhotoFileInputFieldWithLabel--filled' : glimpseBase64String !== '' }"
        class="PhotoFileInputFieldWithLabel"
    >
        <div
            v-show="!showCrop"
            class="PhotoFileInputFieldWithLabel-inputWrapper"
        >
            <label
                :for="`js-file-${fileId}`"
                class="PhotoFileInputFieldWithLabel-label"
            >
                <span class="PhotoFileInputFieldWithLabel-glimpseWrapper">
                    <img
                        v-if="glimpseBase64String !== '' && errorLabel === ''"
                        :src="glimpseBase64String"
                        class="PhotoFileInputFieldWithLabel-glimpse"
                        alt=""
                    >
                    <span class="PhotoFileInputFieldWithLabel-glimpseIconWrapper">
                        <icomoon-icon
                            name="Camera--filled"
                            class="PhotoFileInputFieldWithLabel-glimpseIcon"
                        />
                    </span>
                </span>
                <div>
                    <span
                        v-if="getFileName === ''"
                        class="PhotoFileInputFieldWithLabel-text"
                    >
                        {{ label }}
                    </span>
                    <span
                        v-if="getFileName === '' && description !== ''"
                        class="PhotoFileInputFieldWithLabel-description"
                    >
                        ({{ description }})
                    </span>
                    <span
                        v-if="isFileInstructionsDisplayed && getFileName === '' && getFileInstructions !== ''"
                        class="PhotoFileInputFieldWithLabel-fileFormat"
                        v-html="$sanitize(getFileInstructions)"
                    />
                    <em
                        v-if="getFileName !== ''"
                        class="PhotoFileInputFieldWithLabel-fileName"
                    >
                        {{ getFileName }}
                        <icomoon-icon
                            name="Edit"
                            class="PhotoFileInputFieldWithLabel-icon"
                        />
                    </em>
                </div>
            </label>
            <input
                :id="`js-file-${fileId}`"
                ref="referenceFile"
                :aria-label="label"
                :aria-required="isRequired"
                :required="isRequired"
                class="PhotoFileInputFieldWithLabel-input"
                type="file"
                tabindex="0"
                @focus="focused=true"
                @blur="focused=false"
                @change="handleFileChange"
            >
            <div
                v-if="errorLabel !== ''"
                :aria-label="errorLabel"
                class="PhotoFileInputFieldWithLabel-error"
            >
                {{ errorLabel }}
            </div>
        </div>
        <div
            v-show="getImgSrc && !isPdfFile"
            class="justify-center"
        >
            <v-btn
                v-show="!showCrop && errorLabel === ''"
                color="secondary"
                variant="flat"
                class="PhotoFileInputFieldWithLabel-showCropAreaButton ma-2"
                @click="toggleShowCrop"
            >
                <icomoon-icon
                    name="Crop"
                    class="PhotoFileInputFieldWithLabel-showCropAreaButtonIcon"
                />
                {{ $t('button:crop') }}
            </v-btn>
            <section
                v-show="showCrop"
                class="cropper-area justify-center"
            >
                <div
                    class="img-cropper"
                >
                    <vue-cropper
                        ref="referenceCropper"
                        :minContainerHeight="250"
                        :src="getImgSrc"
                        :aspectRatio="getAspectRatio"
                        preview=".preview"
                    />
                </div>
                <div class="PhotoFileInputFieldWithLabel-cropActions justify-center">
                    <v-btn
                        variant="text"
                        class="PhotoFileInputFieldWithLabel-cropActionButton ma-2"
                        @click.prevent="rotate(90)"
                    >
                        {{ $t('button:rotate') }}
                    </v-btn>
                    <v-btn
                        variant="text"
                        class="PhotoFileInputFieldWithLabel-cropActionButton ma-2"
                        @click.prevent="cropImage"
                    >
                        {{ $t('button:validate') }}
                    </v-btn>
                </div>
            </section>
            <v-checkbox
                v-if="errorLabel === '' && authorizationProviderRetainingDocumentEnabled !== undefined"
                v-model="isAuthorized"
                :label="$t('label:authorization_retaining_document_checkbox')"
                :true-value="false"
                :false-value="true"
                class="PhotoFileInputFieldWithLabel-checkbox"
                color="primary"
                hide-details
                @update:model-value="handleAuthorizationChange"
            />
        </div>
    </div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue'
import IcomoonIcon from '@/StoreWeb/components/common/IcomoonIcon'
import FileManager from '@/StoreWeb/managers/FileManager'
import VueCropper from 'vue-cropperjs'
import i18n from 'i18n'
import 'cropperjs/dist/cropper.css'
import config from 'config'

const emit = defineEmits(['imageCropped', 'fieldValueChanged', 'fileInputError'])

const props = defineProps({
    isRequired: {
        type: Boolean,
        default: true
    },
    description: {
        type: String,
        default: ''
    },
    isFileInstructionsDisplayed: {
        type: Boolean,
        default: true
    },
    fileName: {
        type: String,
        default: ''
    },
    format: {
        type: String,
        default: 'image/jpeg'
    },
    mimeTypes: {
        type: Array,
        default: () => ['image/jpeg', 'image/png', 'application/pdf']
    },
    label: {
        type: String,
        default: 'forms:fileinput:default_label'
    },
    maxSize: {
        type: Number,
        default: 5000000
    },
    fileId: {
        type: [Number, String],
        required: true
    },
    base64String: {
        type: String,
        default: ''
    },
    formatRatio: {
        type: String,
        default: '1:1',
        validator: val => ['1:1', '3:4', '4:3'].includes(val)
    },
    authorizationProviderRetainingDocumentEnabled: {
        type: Boolean,
        default: undefined
    }
})

const errorLabel = ref('')
const file = ref(null)
const glimpseBase64String = ref(props.base64String)
const showCrop = ref(false)
const referenceFile = ref(null)
const referenceCropper = ref(null)
const isAuthorized = ref(true)
const document = ref(null)

const getAspectRatio = computed(() => {
    const [width, height] = props.formatRatio.split(':').map(Number)

    if (!width || !height || width === 0 || height === 0 || isNaN(width) || isNaN(height)) {
        return 0
    }

    return width / height
})
const getImgSrc = computed(() => glimpseBase64String.value)
const getFileName = computed(() => file?.value?.name || '')
const getFileInstructions = computed(() => FileManager.getFileInstructions(props.mimeTypes, config.features.catalog.file_upload_max_size))
const isPdfFile = computed(() => /\.pdf$/i.test(props.fileName))

function handleAuthorizationChange (isAuthorized) {
    if (!document.value) return
    emit('authorizationCheckedChangedUpdated', {
        id: document.value.id,
        isAuthorized,
        file: document.value.file
    })
}

function setImgSrc (src) {
    glimpseBase64String.value = src
}

function cropImage () {
    const base64String = referenceCropper.value.getCroppedCanvas().toDataURL(props.format)
    setImgSrc(base64String)
    toggleShowCrop()
    const payload = {
        id: props.fileId,
        base64String
    }
    if (props.authorizationProviderRetainingDocumentEnabled !== undefined) {
        payload.isAuthorized = isAuthorized.value
    }
    emit('imageCropped', payload)
}

function rotate (deg) {
    referenceCropper.value.rotate(deg)
}

function handleFileChange (event) {
    if (!event.target.files || event.target.files.length === 0 || !checkFileValid()) {
        return
    }
    file.value = event.target.files[0]
    updateGlimpse()
    document.value = {
        id: event.target.id.split('js-file-')[1],
        file: file.value
    }
    if (props.authorizationProviderRetainingDocumentEnabled !== undefined) {
        document.value.isAuthorized = isAuthorized.value
    }
    emit('fieldValueChanged', document.value)
}

function checkFileValid () {
    file.value = referenceFile.value?.files[0] || null
    errorLabel.value = ''
    if (!file.value) {
        setError('forms:fileinput:unknown_error')
        return false
    }
    if (!isAuthorizedExtension()) {
        setError(i18n.global.t('forms:fileinput:wrong_file_type'))
        return false
    }
    if (!FileManager.isAuthorizedSize(file.value.size, config.features.catalog.file_upload_max_size || props.maxSize)) {
        setError(FileManager.getFileSizeExceded(file.value.name || props.fileName, file.value.size, config.features.catalog.file_upload_max_size || props.maxSize))
        return false
    }
    clearError()
    return true
}

function setError (message) {
    errorLabel.value = message
    emit('fileInputError', message)
    file.value = null
}

defineExpose({
    setError,
    fileId: props.fileId
})

function clearError () {
    errorLabel.value = ''
    emit('fileInputError', false)
}

function isAuthorizedExtension () {
    return props.mimeTypes.includes(file.value.type)
}

function updateGlimpse () {
    if (file.value && typeof FileReader === 'function') {
        const reader = new FileReader()
        reader.onload = (event) => {
            glimpseBase64String.value = event.target.result
            referenceCropper.value.replace(event.target.result)
        }
        reader.readAsDataURL(file.value)
    }
    if (!errorLabel.value && file.value) {
        const reader = new FileReader()
        reader.onload = () => {
            glimpseBase64String.value = reader.result
        }
        reader.readAsDataURL(file.value)
    } else {
        glimpseBase64String.value = ''
    }
}

function toggleShowCrop () {
    showCrop.value = !showCrop.value
}

onMounted(() => {
    if (props.authorizationProviderRetainingDocumentEnabled !== undefined) {
        isAuthorized.value = props.authorizationProviderRetainingDocumentEnabled
    }
})
</script>

<style lang='scss' scoped>
@import 'globalScss';

:deep(.v-selection-control) {
    align-items: flex-start !important;
}

:deep(.v-label) {
    align-items: end !important;
}

.PhotoFileInputFieldWithLabel {
    position: relative;
    text-align: center;

    &-label {
        display: block;
        cursor: pointer;
    }

    &-glimpseWrapper {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        position: relative;
        overflow: hidden;
        width: 125px;
        height: 125px;
        border: 1px solid $color-lightgray2;
        border-radius: 125px;
    }

    &-glimpse {
        max-width: 125px;
        max-height: 125px;
    }

    &-glimpseIcon {
        font-size: 64px;
        color: $color-lighterText;

        &Wrapper {
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
        }
    }

    &-text {
        display: block;
        padding-top: 10px;
        font-size: 14px;
        font-weight: $fontWeight-defaultBold;
        color: $color-lightText;
    }

    &-description {
        display: block;
        font-size: 12px;
        color: $color-lightText;
    }

    &-fileFormat {
        display: block;
        font-size: 12px;
        color: $color-lighterText;
    }

    &-fileName {
        display: inline-flex;
        align-items: center;
        font-size: 14px;
        color: $color-brandPrimary;
    }

    &-icon {
        font-size: 20px;
    }

    &-input {
        position: absolute;
        top: -9999px;
        left: -9999px;
    }

    &-error {
        padding-top: 6px;
        font-size: 14px;
        color: $color-danger;
    }

    &-cropActions {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 15px;
    }

    &--format4-3 {
        .PhotoFileInputFieldWithLabel {
            &-glimpseWrapper {
                width: 123px;
                height: 164px;
                border-radius: 6px;
            }

            &-glimpse {
                max-width: 123px;
                max-height: 164px;
            }
        }
    }

    &-checkbox {
        color: $color-defaultText !important;
    }
}

.PhotoFileInputFieldWithLabel--filled {
    .PhotoFileInputFieldWithLabel-glimpseIconWrapper {
        background: rgba(0, 0, 0, .2);
    }

    .PhotoFileInputFieldWithLabel-glimpseIcon {
        color: $color-lightest;
        opacity: .4;
        transition: all .5s;
    }

    .PhotoFileInputFieldWithLabel-glimpseWrapper {
        &:active,
        &:hover {
            .PhotoFileInputFieldWithLabel-glimpseIcon {
                opacity: 1;
            }
        }
    }
}

.cropper-area {
    text-align: center;
}
</style>
