<template>
    <div
        v-show="shouldShowFiltersSidebar"
        class="ProductFilterList"
    >
        <div class="d-flex justify-space-between align-center mb-4">
            <p class="h2 ma-0 mr-6">
                {{ t('catalog:filter:title') }}
            </p>
            <v-btn
                color="primary"
                variant="text"
                @click="clearAllFilters"
            >
                {{ t('button:reset') }}
            </v-btn>
        </div>
        <div
            v-for="filter in searchDisplayModeFilters"
            :key="`filter-${filter.id}`"
            class="ProductFilterList-filter mb-8"
        >
            <p class="h3">
                {{ t('catalog:filter:search:title') }}
            </p>
            <v-text-field
                v-model="filter.value"
                :label="t('catalog:filter:search:label')"
                :placeholder="t('catalog:filter:search:placeholder')"
                clearable
                variant="underlined"
                @update:model-value="searchInProducts"
            />
        </div>
        <template
            v-for="filter in appliedFilters"
            :key="`filter-${filter.id}`"
        >
            <div
                v-if="['BUTTON', 'BUTTON_WITH_ICON'].includes(filter.displayMode)"
                class="ProductFilterList-filter mb-8"
            >
                <p
                    :id="`js-filter-section-${filter.id}`"
                    class="h3"
                >
                    {{ filter.name }}
                </p>
                <ul
                    v-if="filter.displayMode === 'BUTTON'"
                    class="ProductFilterList-filterOptionList d-flex flex-wrap justify-start"
                >
                    <li
                        v-for="option in filter.filters"
                        :key="`option-${option.id}`"
                    >
                        <v-checkbox
                            v-model="filter.value"
                            :class="{ 'ProductFilterList-filterOptionItem--active': filter.value === option.id }"
                            :aria-labelledby="`js-filter-section-${filter.id}`"
                            :label="option.name"
                            :value="option.id"
                            :hide-details="true"
                            class="ProductFilterList-filterOptionItem--button ma-0 pt-0 rounded-xl elevation-2"
                            @update:model-value="onFilterChanged"
                        >
                            <template #label>
                                <div class="py-3 px-4">
                                    {{ option.name }}
                                </div>
                            </template>
                        </v-checkbox>
                    </li>
                </ul>
                <ul
                    v-if="filter.displayMode === 'BUTTON_WITH_ICON'"
                    class="ProductFilterList-filterOptionList d-flex flex-wrap justify-start"
                >
                    <li
                        v-for="option in filter.filters"
                        :key="`option-${option.id}`"
                    >
                        <v-checkbox
                            v-model="filter.value"
                            :class="{ 'ProductFilterList-filterOptionItem--active': filter.value === option.id }"
                            :aria-labelledby="`js-filter-section-${filter.id}`"
                            :label="option.name"
                            :value="option.id"
                            class="ProductFilterList-filterOptionItem--buttonWithIcon ma-0 pt-0 rounded-xl elevation-2"
                            density="compact"
                            @update:model-value="onFilterChanged"
                        >
                            <template #label>
                                <div class="ProductFilterList-filterOptionItemLabelContent d-flex flex-column justify-center align-center py-3 px-4 px-lg-1 px-xl-4">
                                    <v-icon
                                        v-if="option.icon !== null"
                                        :icon="option.icon"
                                    />
                                    {{ option.name }}
                                </div>
                            </template>
                        </v-checkbox>
                    </li>
                </ul>
            </div>
            <div
                v-if="['CHECKBOX', 'SELECT', 'MULTI_SELECT'].includes(filter.displayMode)"
                class="ProductFilterList-filter mb-8"
            >
                <p
                    :id="`js-filter-section-${filter.id}`"
                    class="h3"
                >
                    {{ filter.name }}
                </p>
                <v-autocomplete
                    v-if="isSelectFilter(filter.displayMode)"
                    v-model="filter.value"
                    v-model:search="filter.search"
                    :chips="filter.isMultiple"
                    :custom-filter="filterSuggestion"
                    :hide-details="true"
                    :hide-selected="true"
                    :items="getFormattedChoices(filter.filters)"
                    :label="t('catalog:filter:choose')"
                    :multiple="filter.isMultiple"
                    :no-data-text="$t('autocomplete:no_result')"
                    class="ProductFilterList-filterSelect tonal-background"
                    clearable
                    density="compact"
                    item-color="primary"
                    item-title="text"
                    item-value="value"
                    return-object
                    variant="outlined"
                    @update:model-value="onFilterChanged(filter)"
                    @update:menu="resetSearch(filter)"
                    @click="focusSearchInput(filter.id)"
                >
                    <template #prepend-item>
                        <v-list-item>
                            <v-text-field
                                :ref="(el) => setDynamicSearchInputRef(el, filter.id)"
                                v-model="filter.search"
                                clearable
                                variant="underlined"
                                @keydown="(event) => onDynamicSearchInputKeyDown(event, filter.id)"
                                @update:model-value="() => filtersFilterSuggestions(filter)"
                            />
                        </v-list-item>
                    </template>
                    <template
                        v-if="filter.isMultiple"
                        #chip="data"
                    >
                        <v-chip
                            :key="`chip-${filter.id}-${data.item.value}`"
                            v-bind="data.attrs"
                            :disabled="data.disabled"
                            :model-value="data.selected"
                            closable
                            color="primary"
                            size="small"
                            variant="flat"
                            @click:close="removeSelectedItem(filter, data.item)"
                        >
                            {{ data.item.title }}
                        </v-chip>
                    </template>
                    <template
                        #item="{ props: activatorProps, item }"
                    >
                        <v-list-item
                            v-bind="activatorProps"
                            :key="`item-${filter.id}-${item.value}`"
                            :ref="(el) => setListItemRef(el, filter.id)"
                            aria-selected="false"
                            role="option"
                            tabindex="-2"
                            variant="flat"
                        >
                            <template #title>
                                <div
                                    v-html="purifyHtml(highlightMatch(item))"
                                />
                            </template>
                        </v-list-item>
                    </template>
                    <template
                        v-if="filter.isMultiple"
                        #clear
                    >
                        <v-icon
                            color="error"
                            @click="() => clearCombobox(filter)"
                        >
                            mdi-close-circle
                        </v-icon>
                    </template>
                </v-autocomplete>
                <ul
                    v-else
                    class="ProductFilterList-filterOptionList ProductFilterList-filterOptionList--checkbox d-flex flex-column flex-wrap justify-start"
                >
                    <li
                        v-for="option in filter.filters"
                        :key="`option-${option.id}`"
                    >
                        <v-checkbox
                            v-model="filter.value"
                            :aria-labelledby="`js-filter-section-${filter.id}`"
                            :label="option.name"
                            :value="option.id"
                            :hide-details="true"
                            density="compact"
                            class="ProductFilterList-filterOptionItem ma-0 pa-0"
                            @update:model-value="onFilterChanged"
                        />
                    </li>
                </ul>
            </div>
        </template>
    </div>
</template>

<script setup>
import { computed, nextTick, onBeforeMount, ref } from 'vue'
import { useStore } from 'vuex'
import { useCatalogUrlFilters } from '@/StoreWeb/js/composables/catalog-filters'
import { useI18n } from 'vue-i18n'
import { useDisplay } from 'vuetify'
import { filterSuggestion, highlightMatch, resetMatches } from '@/StoreWeb/js/mixins/autocomplete-utils'
import DOMPurify from 'dompurify'

defineProps({
    shouldShowFiltersSidebar: {
        type: Boolean,
        default: false
    }
})

const { lgAndUp } = useDisplay()
const store = useStore()
const { t } = useI18n()

let searchInProductsTimeout = null

const checkboxListFilterPanels = ref({})
const listItemRefs = ref({})
const selectSearchInputRefs = ref({})
const { applyFiltersFromQueryParams, resetFilters, updateFilters, appliedFilters } = useCatalogUrlFilters()

const getFilters = computed(() => store.state.catalogModule.filters)
const checkboxDisplayModeFilters = computed(() => appliedFilters.value.filter(item => !['BUTTON', 'BUTTON_WITH_ICON', 'SEARCH', 'SELECT', 'MULTI_SELECT'].includes(item.displayMode)))
const searchDisplayModeFilters = computed(() => appliedFilters.value.filter(item => item.displayMode === 'SEARCH'))

function onFilterChanged (filter) {
    if (lgAndUp.value) {
        updateFilters()

        if (typeof filter === 'object') {
            resetSearch(filter)
        }
    }
}

function clearCombobox (filter) {
    filter.value = filter.isMultiple ? [] : null
    onFilterChanged(filter)
}

function clearAllFilters () {
    resetFilters()
}

function getFormattedChoices (array) {
    return array.map(item => {
        return {
            text: item.name,
            value: item.id
        }
    })
}

function isSelectFilter (displayMode) {
    return displayMode === 'SELECT' || displayMode === 'MULTI_SELECT'
}

function purifyHtml (html) {
    return DOMPurify.sanitize(html, { ADD_TAGS: ['span'], ADD_ATTR: ['class'] })
}

function removeSelectedItem (filter, selectedChoice) {
    filter.value = filter.value.filter(choice => choice.value !== selectedChoice.value)
    onFilterChanged(filter)
}

function resetSearch (filter) {
    if (filter) {
        filter.search = ''
        resetMatches(filter.filteredItems)
    }
}

function searchInProducts () {
    clearTimeout(searchInProductsTimeout)

    searchInProductsTimeout = setTimeout(() => {
        onFilterChanged()
    }, 300)
}

function setDynamicSearchInputRef (el, filterId) {
    if (el) {
        selectSearchInputRefs.value[`selectSearchInput-${filterId}`] = el
    }
}

function focusSearchInput (filterId) {
    nextTick(() => {
        const searchFieldRef = selectSearchInputRefs.value[`selectSearchInput-${filterId}`]

        if (typeof searchFieldRef?.focus === 'function') {
            searchFieldRef.focus()
        }
    })
}

function setListItemRef (el, filterId) {
    if (!listItemRefs.value[filterId]) {
        listItemRefs.value[filterId] = []
    }

    if (el) {
        listItemRefs.value[filterId].push(el)
    }
}

function isElementVisible (el) {
    return el.offsetParent !== null
}

function onDynamicSearchInputKeyDown (event, filterId) {
    if (event.key === 'ArrowDown') {
        nextTick(() => {
            const firstVisibleItem = listItemRefs.value[filterId]?.find(el => isElementVisible(el.$el))

            if (firstVisibleItem && typeof firstVisibleItem.$el.focus === 'function') {
                firstVisibleItem.$el.focus()
            }
        })
    }
}

function filtersFilterSuggestions (filter) {
    resetMatches(filter.filteredItems)

    filter.filteredItems = filter.filters.filter(item => {
        if (filter.search) {
            return item.name.toLowerCase().includes(filter.search.toLowerCase())
        }

        return true
    })
}

onBeforeMount(() => {
    applyFiltersFromQueryParams(getFilters)
    for (const filter of checkboxDisplayModeFilters.value) {
        checkboxListFilterPanels.value[filter.id] = 0
    }
})
</script>

<style lang="scss" scoped>
@import 'globalScss';

.ProductFilterList {
    &-filterOptionList {
        gap: $s3;

        :deep(.v-messages) {
            display: none;
        }
    }

    &-filterOptionItem {
        width: 100%;
        height: auto;
    }

    &-expansionPanels {
        :deep(.v-expansion-panel-header) {
            min-height: 32px;
        }

        :deep(.v-expansion-panel-content__wrap) {
            padding: 0;
        }
    }

    &-filterOptionList--checkbox {
        gap: $s5;

        :deep(.v-input__slot) {
            margin: 0;
        }
    }

    &-filterOptionItem--button,
    &-filterOptionItem--buttonWithIcon {
        position: relative;
        overflow: hidden;
        font-weight: normal;

        :deep(.v-selection-control__input),
        :deep(.v-selection-control__wrapper) {
            width: 1px;
            height: 1px;

            .v-icon {
                font-size: 0;
            }
        }

        :deep(.v-selection-control--focused) {
            background: rgba($button-primary-backgroundColor, .3);
        }

        :deep(.v-input__control),
        :deep(.v-input__slot) {
            display: block;
            min-height: 0;
            margin: 0;
        }

        :deep(.v-label) {
            width: 100%;
        }

        :deep(.v-icon) {
            font-size: 48px;
        }

        &.ProductFilterList-filterOptionItem--active {
            background: $button-primary-backgroundColor;
            font-weight: $fontWeight-defaultBold;

            :deep(.v-label),
            :deep(.v-icon) {
                color: $button-primary-foregroundColor;
                opacity: 1;
            }
        }
    }

    &-filterOptionItem--buttonWithIcon {
        min-width: calc((100% - 24px) / 3);

        @media #{map-get($display-breakpoints, 'sm-and-up')} {
            min-width: 116px;
        }

        @media #{map-get($display-breakpoints, 'lg-and-up')} {
            min-width: calc((100% - 24px) / 3);
        }

        .ProductFilterList-filterOptionItemLabelContent {
            gap: $s2;
            width: 100%;
        }
    }

    :deep(.v-select__selection) {
        font-size: 14px;
        white-space: normal;
    }

    &-buttonContainer {
        width: 100%;
        text-align: right;

        @media #{map-get($display-breakpoints, 'lg-and-up')} {
            display: none;
        }
    }
}
</style>
