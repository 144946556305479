<template>
    <modal
        v-model="showModal"
        :title="$t(getLabel)"
        :subtitle="getSubtitle"
        class="AddAccountModal"
        @close-modal="closeModal"
    >
        <v-form @submit.prevent="submit">
            <template
                v-for="field in selectedTemplate"
                :key="field.id"
            >
                <v-text-field
                    v-if="field.type === 'text'"
                    v-model="formValues[field.id]"
                    :error-messages="v$[field.id].$errors[0]?.$message ? $t(v$[field.id].$errors[0].$message) : ''"
                    :label="$t(field.label)"
                    :disabled="field.readOnly"
                    variant="underlined"
                    @update:model-value="updateModelValue(field)"
                />
                <v-text-field
                    v-if="field.type === 'date'"
                    v-model="formValues[field.id]"
                    type="date"
                    :error-messages="v$[field.id].$errors[0]?.$message ? $t(v$[field.id].$errors[0].$message) : ''"
                    :label="$t(field.label)"
                    :disabled="field.readOnly"
                    variant="underlined"
                    @update:model-value="updateModelValue(field)"
                />
                <v-autocomplete
                    v-if="field.type === 'choice'"
                    v-model="formValues[field.id]"
                    variant="underlined"
                    :error-messages="v$[field.id].$errors[0]?.$message ? $t(v$[field.id].$errors[0].$message) : ''"
                    :items="field.params.list"
                    :label="$t(field.label)"
                    :item-title="field.params.list[0]?.label ? 'label' : 'name'"
                    :item-value="field.params.list[0]?.value ? 'value' : 'id'"
                    :disabled="field.readOnly"
                    @update:model-value="updateModelValue(field)"
                />
            </template>
        </v-form>
        <template #actions>
            <v-btn
                variant="text"
                @click="closeModal"
            >
                {{ $t('button:cancel') }}
            </v-btn>
            <v-btn
                class="bg-primary ml-2"
                @click="submitForm"
            >
                {{ $t('address:account:creation') }}
            </v-btn>
        </template>
    </modal>
</template>

<script setup>
import { computed, onBeforeMount, reactive, ref } from 'vue'
import { useStore } from 'vuex'
import * as userActionTypes from '@/StoreWeb/store/modules/user/action-types'
import { useVuelidate } from '@vuelidate/core'
import { regex, required } from '@/StoreWeb/utils/i18n-validators'
import { helpers } from '@vuelidate/validators'
import { useI18n } from 'vue-i18n'
import * as addressActionTypes from '@/StoreWeb/store/modules/address/action-types'
import AddressManager from '@/StoreWeb/managers/AddressManagerSingleton'
import { isEmpty } from 'global-utils'
import {
    initFieldsDependenciesFromTemplate,
    browseAndApplyDependenciesSchemas
} from '@/StoreWeb/js/composables/fields-dependencies-from-template-utils'
import { useLogin } from '@/StoreWeb/js/composables/login-utils'

const emit = defineEmits(['accountFinalized', 'update:modelValue', 'closeModal'])

const props = defineProps({
    modelValue: {
        type: Boolean,
        required: true
    },
    addAccountModalTemplate: {
        type: Object,
        required: true
    }
})

const store = useStore()
const { t } = useI18n()
const { rawUserInfos, userInfos } = useLogin()

const selectedTemplate = ref(props.addAccountModalTemplate.fields)
const addressManager = reactive(AddressManager.getInstance())
const templateCode = ref('USER')

const showModal = computed({
    get: () => props.modelValue,
    set: (newValue) => emit('update:modelValue', newValue)
})
const getLabel = computed(() => props.addAccountModalTemplate?.label)
const getSubtitle = computed(() => t(props.addAccountModalTemplate?.subtitle))
const getAddressTemplates = computed(() => store.getters.getAddressTemplates)
const getUserTemplate = computed(() => getAddressTemplates.value.find(item => item.code === templateCode.value))
const getTemplateWithInitialValues = computed(() => addressManager.setTemplateWithInitialValues(getUserTemplate.value))

const formValues = computed(() => {
    const fields = {}
    selectedTemplate.value?.forEach(field => {
        if (field.id === 'firstName') {
            fields[field.id] = userInfos.value.firstName
        } else if (field.id === 'lastName') {
            fields[field.id] = userInfos.value.lastName
        } else if (field.id === 'birthDate') {
            fields[field.id] = rawUserInfos.value.attributes?.birthDate
            if (!isEmpty(rawUserInfos.value.attributes?.birthDate)) {
                field.readOnly = true
            }
        } else {
            fields[field.id] = field.value ?? ''
        }
    })
    return reactive(fields)
})
const rules = computed(() => {
    const rules = {}
    selectedTemplate.value?.forEach(field => {
        rules[field.id] = {}
        if (field.required) {
            rules[field.id].required = required
        }
        if (field.params?.regex) {
            rules[field.id].regex = helpers.withMessage(
                () => {
                    return t(field.params?.regexValidationMessage ?? 'validations:regex')
                },
                regex(new RegExp(field.params.regex))
            )
        }
    })
    return rules
})

const v$ = useVuelidate(rules, formValues)

onBeforeMount(() => {
    store.dispatch(addressActionTypes.GET_ADDRESS_TEMPLATES)
    initFieldsDependenciesFromTemplate({
        formFieldValues: formValues.value,
        template: selectedTemplate.value,
        vuelidate: v$.value
    })
})

function closeModal () {
    showModal.value = false
    emit('closeModal')
}

async function submitForm () {
    v$.value.$touch()
    const isValid = await v$.value.$validate()
    if (!v$.value.$invalid && isValid) {
        submit()
    }
}

function updateModelValue (field) {
    v$.value[field.id].$touch()
    browseAndApplyDependenciesSchemas({
        formFieldValues: formValues.value,
        field,
        vuelidate: v$.value
    })
}

async function submit () {
    closeModal()

    await store.dispatch(userActionTypes.CREATE_PROVIDER_ACCOUNT, {
        values: formValues.value,
        providerId: 'conduent',
        cachiosOptions: {
            force: true
        }
    })
        .then(async () => {
            formValues.value.defaultAddress = true
            formValues.value.receiverFirstname = formValues.value.firstName
            formValues.value.receiverLastname = formValues.value.lastName
            if (!isEmpty(formValues.value?.birthDate)) {
                await store.dispatch(userActionTypes.UPDATE_PERSONAL_INFO, {
                    attributes: {
                        email: userInfos.value.email,
                        firstName: userInfos.value.firstName,
                        lastName: userInfos.value.lastName,
                        birthDate: formValues.value?.birthDate
                    },
                    silent: true
                })
            }
            await store.dispatch(addressActionTypes.CREATE_ADDRESS, {
                data: formValues.value,
                template: getTemplateWithInitialValues.value,
                callback: async () => {
                    await store.dispatch(addressActionTypes.GET_ADDRESSES, {
                        cachiosOptions: {
                            force: true
                        }
                    })
                }
            })
            await store.dispatch(userActionTypes.GET_WALLET, {
                wsOptions: {
                    cacheAsync: false,
                    cacheValidity: 0
                },
                cachiosOptions: {
                    force: true
                }
            })

            emit('accountFinalized')
        })
}
</script>
