<template lang="html">
    <div class="SaleOptionProviderFareMediaUseCardReaderOrOrderCard">
        <h2 class="SaleOptionProviderFareMediaUseCardReaderOrOrderCard-title">
            {{ $t('mandatory_card_title') }}
        </h2>
        <template v-if="showAuthenticationDescription">
            <span
                class="SaleOptionProviderFareMediaUseCardReaderOrOrderCard-description"
                v-html="$sanitize($t('mandatory_card_desc'))"
            />
            <authentication-descriptions class="mt-4 mb-8" />
        </template>
        <div class="SaleOptionProviderFareMediaUseCardReaderOrOrderCard-buttons">
            <v-btn
                color="primary"
                size="large"
                elevation="0"
                type="submit"
                @click="useSupportReader"
            >
                {{ $t('button:read_card') }}
            </v-btn>
        </div>
        <i18n-t
            class="SaleOptionProviderFareMediaUseCardReaderOrOrderCard-requiredProduct"
            keypath="no_faremedia_order_here:message"
            tag="p"
        >
            <span
                class="SaleOptionProviderFareMediaUseCardReaderOrOrderCard-requiredProductLink"
                @click="orderFareMedia"
            >
                {{ $t('no_faremedia_order_here:link_text') }}
            </span>
        </i18n-t>
        <i18n-t
            class="SaleOptionProviderFareMediaUseCardReaderOrOrderCard-requiredProduct"
            keypath="no_card_reader_order_here:message"
            tag="p"
        >
            <span
                class="SaleOptionProviderFareMediaUseCardReaderOrOrderCard-requiredProductLink"
                @click="orderCardReader"
            >
                {{ $t('no_card_reader_order_here:link_text') }}
            </span>
        </i18n-t>
        <support-reader-modal
            v-model="showSupportReaderModal"
            :headerTitle="$t('card_reader:scan_modal:title')"
            :operationType="'READ'"
            @operation-finished="onSupportReaderOperationFinished"
        />
    </div>
</template>

<script setup>
import AuthenticationDescriptions from '@/StoreWeb/components/common/AuthenticationDescriptions'
import SupportReaderModal from '@/StoreWeb/components/miscellaneous/conduent-support-reader/SupportReaderModal'
import i18n from '@/StoreWeb/i18n'
import { checkAndGetFareMedia } from '@/StoreWeb/js/mixins/faremedia-utils'
import { setSelectedFareMediaCookie } from '@/StoreWeb/js/mixins/wallet-utils'
import * as anonymousMutationTypes from '@/StoreWeb/store/modules/anonymous/mutation-types'
import config from 'config'
import { computed, ref } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'

const store = useStore()
const router = useRouter()

const emit = defineEmits(['updateSaleOptionFromCardReaderWithOrderCardFallBack', 'supportReaderBasketInfoUsed'])

const props = defineProps({
    provider: {
        type: String,
        default: ''
    },
    showAuthenticationDescription: {
        type: Boolean,
        default: true
    }
})

const showSupportReaderModal = ref(false)

const providerConfig = computed(() => config.providers.find(provider => provider.id === props.provider))

function useSupportReader () {
    showSupportReaderModal.value = true
    emit('supportReaderBasketInfoUsed', 'PROVIDERFAREMEDIA')
}

async function onSupportReaderOperationFinished (data) {
    if (data?.support?.fareMediaId) {
        const response = await checkAndGetFareMedia({
            isForConsultation: true,
            providerId: props.provider,
            fareMediaId: data.support.fareMediaId
        }, false)

        if (response.isValid && response?.fareMedia?.id) {
            store.commit(anonymousMutationTypes.SET_SELECTED_SUPPORT, response.fareMedia)

            const selectedSupport = {
                providerId: props.provider,
                fareMediaId: response.fareMedia.id
            }
            if (response.fareMedia?.providerUserExternalId) {
                selectedSupport.providerUserExternalId = response.fareMedia.providerUserExternalId
            }
            if (response.fareMedia?.providerUserId) {
                selectedSupport.providerUserId = response.fareMedia.providerUserId
            }

            setSelectedFareMediaCookie(selectedSupport)

            localStorage.setItem('selectedSupport', response.fareMedia.id)
            showSupportReaderModal.value = false

            emit('updateSaleOptionFromCardReaderWithOrderCardFallBack', selectedSupport)
        }
    }
}

function orderRequiredProduct (key) {
    // emitter.emit('saveProductToPutOnCard')

    if (providerConfig.value && providerConfig.value[key]) {
        if (providerConfig.value[key].url) {
            window.location.href = providerConfig.value[key].url
        } else if (providerConfig.value[key].productId) {
            router.push({
                name: 'product',
                params: {
                    id: providerConfig.value[key].productId,
                    lang: i18n.global.locale
                }
            })
        }
    }
}

function orderFareMedia () {
    orderRequiredProduct('order_faremedia_link')
}

function orderCardReader () {
    orderRequiredProduct('order_card_reader_link')
}
</script>

<style lang="scss" scoped>
@import 'globalScss';

.SaleOptionProviderFareMediaUseCardReaderOrOrderCard {
    padding-bottom: $s4;

    &-title {
        margin-bottom: $margin-small;
        font-size: $font-smedium;
        font-weight: bold;
        opacity: .9;
    }

    &-description {
        font-size: $font-small;
    }

    &-buttons {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: $s4;
        padding: $s4 0 $s8;
    }

    &-requiredProductLink {
        cursor: pointer;
        text-decoration: underline;
        color: $color-brandPrimary;

        &:active,
        &:hover {
            text-decoration: none;
        }
    }
}
</style>
