<template>
    <div
        v-if="isDisplayed"
        class="FareMediaProductItem"
    >
        <div
            class="d-flex justify-space-between align-start px-2 pt-3"
        >
            <strong class="FareMediaProductItem-name">{{ fareMediaProduct.label }}</strong>
            <dropdown-menu
                v-if="getActions.length > 0"
                padding-bottom="0"
                padding-top="0"
            >
                <ul class="DropdownMenu-list">
                    <li
                        v-for="action in getActions"
                        :key="action.id"
                        class="DropdownMenu-item"
                    >
                        <v-btn
                            v-if="action.url != null && action.type !== 'RENEW_WITH_RENEWAL_PRODUCT' && action.type !== 'RELOAD_WITH_RENEWAL_PRODUCT'"
                            :href="sanitizeUrl(action.url)"
                            class="justify-start w-100 rounded-0"
                            color="primary"
                            size="small"
                            variant="text"
                            target="_blank"
                        >
                            {{ action.label }}
                        </v-btn>
                        <v-btn
                            v-if="action.type === 'RELOAD_WITH_RENEWAL_PRODUCT' || action.type === 'RENEW_WITH_RENEWAL_PRODUCT'"
                            class="DropdownMenu-listItemButton justify-start w-100 rounded-0"
                            color="primary"
                            size="small"
                            variant="text"
                            @click.prevent="getRenewLink"
                        >
                            {{ action.label }}
                        </v-btn>
                        <v-btn
                            v-else
                            class="DropdownMenu-listItemButton justify-start w-100 rounded-0"
                            color="primary"
                            size="small"
                            variant="text"
                            @click.prevent="executeAction(action)"
                        >
                            {{ action.label }}
                        </v-btn>
                    </li>
                </ul>
            </dropdown-menu>
        </div>
        <div class="px-2">
            {{ fareMediaProduct.providerNetworkLabel }}
        </div>
        <div class="pb-2">
            <div
                v-if="getValidityStartDate !== ''"
                class="ma-2"
                v-html="$sanitize(getValidityStartDate)"
            />
            <div
                v-if="getValidityEndDate !== ''"
                class="ma-2"
                v-html="$sanitize(getValidityEndDate)"
            />
            <div
                v-if="getRemainingTickets !== ''"
                class="ma-2"
                v-html="$sanitize(getRemainingTickets)"
            />
            <div
                v-if="getDescription !== ''"
                class="ma-2"
                v-html="$sanitize(getDescription)"
            />
            <v-row class="justify-space-between ma-2 mb-1">
                <em class="font-italic">{{ fareMediaProduct.status.label }}</em>
            </v-row>
        </div>
    </div>
</template>

<script setup>
import { sanitizeUrl } from '@braintree/sanitize-url'
import DropdownMenu from '@/StoreWeb/components/common/dropdown-menu/DropdownMenu'
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import moment from 'moment'
import store from '@/StoreWeb/store/store'
import * as userMutationTypes from '@/StoreWeb/store/modules/user/mutation-types'
import router from '@/router/router'

const emit = defineEmits(['suspendSubscription'])

const props = defineProps({
    fareMediaProduct: {
        type: Object,
        required: true
    },
    fareMedia: {
        type: Object,
        required: true
    },
    showActions: {
        type: Boolean,
        default: true
    }
})

const { t } = useI18n()

const getActions = computed(() => props.fareMediaProduct.actions)

const getDescription = computed(() => {
    if (props.fareMediaProduct.description !== props.fareMediaProduct.name) {
        return props.fareMediaProduct.description.replace('\n', '<br>')
    }
    return ''
})

const getValidityEndDate = computed(() => {
    if (props.fareMediaProduct.endDate !== '') {
        return t('date:end_of_validity').replace('%date%', `<strong>${moment(props.fareMediaProduct.endDate).format(t('date_format:default'))}</strong>`)
    }
    return ''
})

const getValidityStartDate = computed(() => {
    if (props.fareMediaProduct.startDate !== '') {
        return t('date:start_date').replace('%date%', `<strong>${moment(props.fareMediaProduct.startDate).format(t('date_format:default'))}</strong>`)
    }
    return ''
})

const getRemainingTickets = computed(() => {
    if (props.fareMediaProduct.remainingTickets !== null) {
        if (props.fareMediaProduct.remainingTickets > 1) {
            return t('fare_media:remaing_tickets:several').replace('%remainingTickets%', `<strong>${props.fareMediaProduct.remainingTickets}</strong>`)
        } else if (props.fareMediaProduct.remainingTickets === 1) {
            return t('fare_media:remaing_tickets:one').replace('%remainingTickets%', `<strong>${props.fareMediaProduct.remainingTickets}</strong>`)
        }
    }
    return ''
})

const isDisplayed = computed(() => props.fareMediaProduct.type.id !== 'FAREMEDIA_RIGHT')

function executeAction (action) {
    if (action.type === 'PRODUCT_TEMPORARILY_SUSPEND_SUBSCRIPTION') {
        suspendSubscription(action)
    }
}

async function suspendSubscription (action) {
    const data = {
        actionType: action.type,
        providerId: props.fareMediaProduct.provider,
        accountId: props.fareMedia.accountId,
        fareMediaId: props.fareMedia.id,
        productId: props.fareMediaProduct.id
    }
    emit('suspendSubscriptionButtonClicked', data)
}

function getRenewLink () {
    if (!props.fareMediaProduct) {
        return null
    }
    store.commit(userMutationTypes.SET_FARE_MEDIA_PRODUCT, props.fareMediaProduct)

    return router.push({ name: 'renewalProducts' })
}
</script>

<style lang="scss" scoped>
@import 'globalScss';

.FareMediaProductItem {
    font-size: 12px;
    color: $color-lighterText;

    &-name {
        font-size: 14px;
        color: $color-lightText;
    }
}
</style>
