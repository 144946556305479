<template>
    <div class="DocumentsSelector">
        <h2
            v-if="title !== ''"
            class="DocumentsSelector-title"
        >
            {{ title }}
        </h2>
        <photo-file-input-field-with-label
            v-for="document in getPhotoTypeDocuments"
            :key="document.id"
            ref="photoFileInputFieldWithLabelRefs"
            :formatRatio="'3:4'"
            :fileId="document.id"
            :authorizationProviderRetainingDocumentEnabled="document.authorizationProviderRetainingDocumentEnabled"
            :isFileInstructionsDisplayed="true"
            :mimeTypes="document.mimeTypes"
            :maxSize="document.maxSize"
            v-bind="document"
            class="DocumentsSelector-field DocumentsSelector-photoField"
            @file-input-error="setUploadedFileError"
            @field-value-changed="documentChanged"
            @image-cropped="imageCropped"
            @authorization-checked-changed-updated="authorizationCheckedChangedUpdated"
        />
        <template v-if="getNotPhotoTypeDocuments.length">
            <p
                v-if="otherFilesTitle"
                class="DocumentsSelector-otherFilesTitle"
            >
                {{ otherFilesTitle }}
            </p>
            <file-input-field-with-label
                v-for="document in getNotPhotoTypeDocuments"
                :key="document.id"
                ref="fileInputFieldWithLabelRefs"
                :fileId="document.id"
                :isFileInstructionsDisplayed="true"
                :mimeTypes="document.mimeTypes"
                :maxSize="document.maxSize"
                v-bind="document"
                class="DocumentsSelector-field DocumentsSelector-fileField"
                @file-input-error="setUploadedFileError"
                @field-value-changed="documentChanged"
            />
        </template>
    </div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue'
import { isEmpty } from 'global-utils'
import FileInputFieldWithLabel from '@/StoreWeb/components/common/FileInputFieldWithLabel'
import DocumentManager from '@/StoreWeb/managers/DocumentManagerSingleton'
import PhotoFileInputFieldWithLabel from '@/StoreWeb/components/common/PhotoFileInputFieldWithLabel'
import * as actionTypes from '@/StoreWeb/store/modules/main/action-types'
import * as cartMutationTypes from '@/StoreWeb/store/modules/cart/mutation-types'
import { useStore } from 'vuex'
import config from 'config'
import { processFile } from '@/StoreWeb/js/composables/file-processing-service-utils'
import * as mutationTypes from '@/StoreWeb/store/modules/main/mutation-types'
import i18n from 'i18n'

const emit = defineEmits(['updateDocuments'])

const props = defineProps({
    documents: {
        type: Array,
        required: true
    },
    otherFilesTitle: {
        type: String,
        default: ''
    },
    providedDocuments: {
        type: Array,
        default: () => []
    },
    title: {
        type: String,
        default: ''
    }
})

const store = useStore()

const selectedDocuments = ref([])
const uploadedFileError = ref(false)

const documentManager = DocumentManager.getInstance()

const getNotPhotoTypeDocuments = computed(() => selectedDocuments.value.filter(document => !isPhotoDocument(document)))
const getPhotoTypeDocuments = computed(() => selectedDocuments.value.filter(document => isPhotoDocument(document)))
const fileInputFieldWithLabelRefs = ref(null)
const photoFileInputFieldWithLabelRefs = ref(null)

onMounted(() => {
    if (config.features.catalog.file_upload) {
        store.commit(cartMutationTypes.SET_IS_GO_TO_NEXT_STEP_DISABLED, true)
    }
    setDocuments()
    if (!isEmpty(props.providedDocuments)) {
        emit('updateDocuments', selectedDocuments.value)

        store.commit(cartMutationTypes.SET_IS_GO_TO_NEXT_STEP_DISABLED, false)
    }
})

function createFormattedDocument (document, providedDocument = null) {
    return {
        base64String: '',
        cropAvailable: document.cropAvailable,
        cropEnabled: document.cropEnabled,
        description: document.description || '',
        fileName: '',
        fileObject: null,
        id: document.id,
        label: document.name,
        maxSize: document.maxSize,
        mimeTypes: document.mimeTypes,
        authorizationProviderRetainingDocumentEnabled: document.authorizationProviderRetainingDocumentEnabled ?? undefined,
        ...providedDocument && {
            fileObject: providedDocument.fileObject || null,
            fileName: providedDocument.fileName || '',
            base64String: providedDocument.base64String || ''
        }
    }
}

function setDocuments () {
    props.documents.forEach(document => {
        const providedDocument = props.providedDocuments?.find(item => item.id === document.id) || null
        const formattedDocument = createFormattedDocument(document, providedDocument)
        selectedDocuments.value.push(formattedDocument)
    })
}

function resetDocumentById (id) {
    const documentIndex = selectedDocuments.value.findIndex(doc => doc.id === id)
    if (documentIndex !== -1) {
        const originalDocument = props.documents.find(doc => doc.id === id)
        if (originalDocument) {
            const providedDocument = props.providedDocuments?.find(item => item.id === id) || null
            selectedDocuments.value[documentIndex] = createFormattedDocument(originalDocument, providedDocument)
        }
    }
}

async function processAndUpload (file, documentToProcess, idParsed) {
    store.commit(mutationTypes.SET_SHOW_GLOBAL_LOADER, { value: false })

    const handleUpload = (fileObject) => {
        const uploadConfig = getUploadConfig(idParsed, fileObject)
        uploadConfig.id = idParsed
        store.dispatch(actionTypes.UPLOAD_DOCUMENT, uploadConfig)
    }

    const setGlobalLoader = (state) => store.commit(mutationTypes.SET_SHOW_GLOBAL_LOADER, { value: state })

    try {
        if ((config.features.catalog.file_compression && config.features.catalog.file_upload) || store.state.debuggingModule.fileCompressionConf) {
            setGlobalLoader(true)

            const compressedFile = await processFile(file, documentToProcess)
            if (compressedFile?.fileObject?.size === 0 || compressedFile?.fileObject?.size > compressedFile.maxSize) {
                throw new Error(i18n.global.t('document:processing:error'))
            }

            documentToProcess = compressedFile
            const documentIndex = selectedDocuments.value.findIndex(doc => doc.id === idParsed)
            if (documentIndex !== -1) selectedDocuments.value[documentIndex] = documentToProcess

            handleUpload(compressedFile.fileObject)
        } else if (config.features.catalog.file_upload) {
            handleUpload(file)
        } else {
            emit('updateDocuments', selectedDocuments.value)
        }
    } catch (error) {
        uploadedFileError.value = true
        const fileInputFieldWithLabelRefById = getFileInputFieldWithLabelRefsById(idParsed)
        resetDocumentById(idParsed)
        if (fileInputFieldWithLabelRefById && typeof fileInputFieldWithLabelRefById.setError === 'function') {
            fileInputFieldWithLabelRefById.setError(error.message)
        }
        store.commit(cartMutationTypes.SET_IS_GO_TO_NEXT_STEP_DISABLED, true)
    } finally {
        setGlobalLoader(false)
        store.commit(cartMutationTypes.SET_IS_GO_TO_NEXT_STEP_DISABLED, true)
        uploadedFileError.value = false
    }
}

function getFileInputFieldWithLabelRefsById (id) {
    if (!fileInputFieldWithLabelRefs.value && !photoFileInputFieldWithLabelRefs.value) {
        return
    }

    return (
        fileInputFieldWithLabelRefs.value?.find(child => child?.fileId === id) ||
        photoFileInputFieldWithLabelRefs.value?.find(child => child?.fileId === id)
    )
}

function documentChanged (document) {
    store.commit(mutationTypes.SET_SHOW_GLOBAL_LOADER, { value: true })

    // Guard clauses to verify basic conditions
    if (uploadedFileError.value || !document?.id) {
        store.commit(mutationTypes.SET_SHOW_GLOBAL_LOADER, { value: false })
        store.commit(cartMutationTypes.SET_IS_GO_TO_NEXT_STEP_DISABLED, true)
        uploadedFileError.value = true
        return
    }

    const file = document.file
    const documentToProcess = selectedDocuments.value.find(doc => doc.id === document.id)
    if (documentToProcess?.authorizationProviderRetainingDocumentEnabled !== undefined) {
        documentToProcess.authorizationProviderRetainingDocumentEnabled = document.isAuthorized
    }

    if (!documentToProcess || !file) {
        store.commit(cartMutationTypes.SET_IS_GO_TO_NEXT_STEP_DISABLED, true)
        store.commit(mutationTypes.SET_SHOW_GLOBAL_LOADER, { value: false })
        uploadedFileError.value = true
        return
    }

    selectedDocuments.value = documentManager.updateDocuments(document.id, file, selectedDocuments.value)

    processAndUpload(file, documentToProcess, document.id)
}

function imageCropped (params) {
    documentManager.cropImage(params, props.documents)

    if (!isEmpty(params.base64String)) {
        const updatedDocument = getDocumentById(params.id)
        const croppedFile = documentManager.dataURLToFile(params.base64String, updatedDocument.fileName)

        const documentToProcess = selectedDocuments.value.find(doc => doc.id === params.id)
        if (documentToProcess) {
            processAndUpload(croppedFile, documentToProcess, params.id)
        }
    }
}

function authorizationCheckedChangedUpdated (document) {
    const documentToProcess = selectedDocuments.value.find(doc => doc.id === document.id)
    if (documentToProcess?.authorizationProviderRetainingDocumentEnabled !== undefined) {
        documentToProcess.authorizationProviderRetainingDocumentEnabled = document.isAuthorized
    }

    const documentIndex = selectedDocuments.value.findIndex(doc => doc.id === document.id)
    if (documentIndex !== -1) {
        selectedDocuments.value[documentIndex] = { ...documentToProcess }
    }

    selectedDocuments.value = documentManager.updateDocuments(document.id, document.file, selectedDocuments.value)

    emit('updateDocuments', selectedDocuments.value)
    if (isAllDocumentsLoaded()) {
        store.commit(cartMutationTypes.SET_IS_GO_TO_NEXT_STEP_DISABLED, false)
    }
}

function getDocumentById (id) {
    return selectedDocuments.value.find(document => document.id === id)
}

function getUploadConfig (documentId, file, fileName) {
    const formData = new FormData()
    if (!isEmpty(fileName)) {
        formData.append('file', file, fileName)
    } else {
        formData.append('file', file)
    }

    return {
        formData,
        callback: (data) => {
            const updatedDocument = getDocumentById(documentId)
            updatedDocument.uploadedFileId = data.documentId
            emit('updateDocuments', selectedDocuments.value)
            if (isAllDocumentsLoaded()) {
                store.commit(cartMutationTypes.SET_IS_GO_TO_NEXT_STEP_DISABLED, false)
            }
        }
    }
}

function isAllDocumentsLoaded () {
    return selectedDocuments.value.every(document => !isEmpty(document.uploadedFileId))
}

function isPhotoDocument (document) {
    return document.cropAvailable && document.cropEnabled
}

function setUploadedFileError (error) {
    uploadedFileError.value = error !== false
}
</script>

<style lang="scss" scoped>
@import 'globalScss';

.DocumentsSelector {
    &-title {
        margin: 0 0 10px;
        font-size: 18px;
    }

    &-otherFilesTitle {
        margin: 0;
        font-size: 14px;
        font-weight: bold;
    }

    &-field {
        margin-bottom: 15px;

        & + .DocumentsSelector-otherFilesTitle {
            margin-top: 30px;
        }
    }
}
</style>
