<template>
    <div class="AuthenticationModeAnonymous">
        <p class="AuthenticationModeAnonymous-explanation">
            {{ $t('anonymous_authentication:title') }}
        </p>
        <div class="AuthenticationModeAnonymous-form">
            <v-text-field
                :label="$t('form:user_infos:email:label')"
                :rules="emailRules"
                :model-value="getDefaultValue"
                autocomplete="email"
                class="AuthenticationModeAnonymous-field"
                clearable
                required
                type="email"
                variant="underlined"
                @update:model-value="emailUpdated"
            >
                <template #append>
                    <v-btn
                        :disabled="getIsEmailValid ? false : 'disabled'"
                        class="AuthenticationModeAnonymous-button v-btn__iconStyle"
                        color="primary"
                        elevation="0"
                        @click.stop="updateEmail"
                    >
                        <icomoon-icon
                            name="Check"
                            class="AuthenticationModeAnonymous-buttonIcon"
                        />
                        {{ $t('button:validate') }}
                    </v-btn>
                </template>
            </v-text-field>
        </div>
    </div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue'
import { useStore } from 'vuex'
import { isEmpty } from 'global-utils'
import AddToCartFlowManager from '@/StoreWeb/managers/AddToCartFlowManagerSingleton'
import IcomoonIcon from '@/StoreWeb/components/common/IcomoonIcon'
import i18n from 'i18n'
import config from 'config'

const emit = defineEmits(['updateEmail'])

const store = useStore()

const addToCartFlowManager = ref(AddToCartFlowManager.getInstance())
const selectedEmail = ref(null)

const emailRules = [
    function (v) {
        return !!v || i18n.global.t('validations.required')
    },
    function (v) {
        return new RegExp(config.patterns.email, 'i').test(v) ||
      i18n.global.t('validations.email').replace('{model}', v)
    }
]

const addToCartEmail = computed(() => store.state.cartModule.addToCartEmail)

const getDefaultValue = computed(() => {
    if (!isEmpty(selectedEmail.value)) {
        return selectedEmail.value
    }

    return ''
})

const getIsEmailValid = computed(() => {
    if (!isEmpty(selectedEmail.value)) {
        return !isEmpty(selectedEmail.value.match(config.patterns.email))
    }

    return false
})

function emailUpdated (email) {
    selectedEmail.value = email
}

function updateEmail () {
    emit('updateEmail', selectedEmail.value)
}

onMounted(() => {
    const currentStep = addToCartFlowManager.value.currentStep

    if (
        !isEmpty(currentStep.selectedAuthenticationMode) &&
        currentStep.selectedAuthenticationMode === 'EMAILMANDATORY' &&
        !isEmpty(currentStep.selectedAuthenticationModeParameters) &&
        !isEmpty(currentStep.selectedAuthenticationModeParameters.email)
    ) {
        selectedEmail.value = currentStep.selectedAuthenticationModeParameters.email
    } else {
        selectedEmail.value = addToCartEmail.value
    }
})
</script>

<style lang="scss" scoped>
@import 'globalScss';

.AuthenticationModeAnonymous {
    &-buttonIcon {
        font-size: 24px;
    }

    &-explanation {
        text-align: left;
    }
}
</style>
