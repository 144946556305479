<template>
    <div class="ma-0">
        <HomePageComponent
            class="HomePage-component"
            :isAuthenticated
            @cards-button-clicked="manageHomeEvents"
        />
        <support-reader-modal
            v-model="showSupportReaderModal"
            @operation-finished="onOperationFinished"
        />
        <modal-connection
            v-model="showConnectionModal"
            :isMobile="$vuetify.display.smAndDown"
            :type="homeEventsEnum.CATALOG_CONNECTED"
            @connection="goToConnection"
        />
        <logout-confirmation-modal v-model="showControlModal" />
        <reset-anonymous-confirmation-modal
            v-model="showResetModal"
            :type="lastEvent"
            @session-expired="afterSessionExpired"
        />
    </div>
</template>

<script setup>
import SupportReaderModal from '@/StoreWeb/components/miscellaneous/conduent-support-reader/SupportReaderModal.vue'
import { useLogin } from '@/StoreWeb/js/composables/login-utils'
import addCartFlowMixins from '@/StoreWeb/js/mixins/add-cart-flow-utils'
import { sessionExpired } from '@/StoreWeb/js/mixins/inactivity-utils'
import { setSelectedFareMediaCookie } from '@/StoreWeb/js/mixins/wallet-utils'
import * as anonymousActionTypes from '@/StoreWeb/store/modules/anonymous/action-types'
import * as anonymousMutationTypes from '@/StoreWeb/store/modules/anonymous/mutation-types'
import LogoutConfirmationModal from '@/StoreWeb/views/LogoutConfirmationModal.vue'
import ResetAnonymousConfirmationModal from '@/StoreWeb/views/ResetAnonymousConfirmationModal.vue'
import config from 'config'
import { isEmpty } from 'global-utils'
import { computed, nextTick, onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'

const store = useStore()
const router = useRouter()
const { isAuthenticated, kasConfigurationSettings } = useLogin()
const { locale, t } = useI18n()

const { triggerLogin } = addCartFlowMixins()

const showSupportReaderModal = ref(false)
const showConnectionModal = ref(false)
const showControlModal = ref(false)
const showResetModal = ref(false)
const lastEvent = ref(null)

const homeEventsEnum = {
    CATALOG_CONNECTED: 'catalog_connected',
    CATALOG_FULL: 'catalog_full',
    CARD_READER: 'card_reader',
    CREATE_ACCOUNT: 'create_account',
    GO_TO_FAQ: 'go_to_faq',
    GO_TO_SEARCH: 'go_to_search'
}

const getCart = computed(() => store.getters.getCart)
const supportContent = computed(() => store.state.anonymousModule.supportContent)
const hasSelectedSupport = computed(() => {
    const selectedSupport = localStorage.getItem('selectedSupport')
    return !isEmpty(selectedSupport) && selectedSupport !== 'ALL'
})

function manageHomeEvents (event) {
    lastEvent.value = event

    switch (event) {
        case homeEventsEnum.CARD_READER:
            if (isAuthenticated.value) {
                showControlModal.value = true
            } else if (hasSelectedSupport.value) {
                if (!isEmpty(getCart.value) && !getCart.value.empty) {
                    showResetModal.value = true
                } else {
                    sessionExpired(true)
                    openCardReaderModal()
                }
            } else {
                openCardReaderModal()
            }
            break
        case homeEventsEnum.CATALOG_CONNECTED:
            if (isAuthenticated.value) {
                router.push({ name: 'catalog' })
            } else {
                showConnectionModal.value = true
            }
            break
        case homeEventsEnum.CATALOG_FULL:
            if (!isAuthenticated.value && hasSelectedSupport.value) {
                showResetModal.value = true
            } else {
                goToCatalogFull()
            }
            break
        case homeEventsEnum.CREATE_ACCOUNT:
            goToConnection()
            break
        case homeEventsEnum.GO_TO_FAQ:
            window.location.href = `${config.cms_url}${t('homepage:card_component:link:faq')}`
            break
        case homeEventsEnum.GO_TO_SEARCH:
            window.location.href = `${config.cms_url}${t('homepage:card_component:link:search')}`
            break
        default:
            console.warn('Case not managed')
            break
    }
}

function goToConnection () {
    if (!isEmpty(kasConfigurationSettings.value?.pages?.registration)) {
        window.location.href = kasConfigurationSettings.value.pages.registration.setUrlParameter('kc_locale', locale.value)
        return
    }

    // Fallback
    triggerLogin({
        callback: showConnectionModal.value = false,
        route: router.resolve({ name: 'catalog' })
    })
}

function closeControlModal () {
    showControlModal.value = false
}

function closeConnectionModal () {
    showConnectionModal.value = false
}

function closeResetModal () {
    showResetModal.value = false
}

async function onOperationFinished (data) {
    showSupportReaderModal.value = false

    if (!isEmpty(data.support)) {
        const params = {
            fareMediaId: data.support.fareMediaId,
            isTemporary: false,
            providerId: 'conduent'
        }

        setSelectedFareMediaCookie(params, true)
        if (isEmpty(supportContent.value)) {
            const content = await store.dispatch(anonymousActionTypes.GET_SUPPORT_CONTENT, params)

            if (content?.id) {
                store.commit(anonymousMutationTypes.SET_SUPPORT_CONTENT, content)
            }
        }

        router.push({ name: 'walletLight', params })
    }
}

function openCardReaderModal () {
    closeControlModal()
    closeResetModal()
    closeConnectionModal()

    nextTick(() => {
        showSupportReaderModal.value = true
    })
}

function afterSessionExpired () {
    if (lastEvent.value === homeEventsEnum.CATALOG_FULL) {
        goToCatalogFull()
    } else {
        openCardReaderModal()
    }
}

function goToCatalogFull () {
    router.push({
        name: 'catalog',
        query: {
            resetSelectedSupportFilter: 'true',
            needsRefresh: 'false'
        }
    })
}

onMounted(() => {
    // Hack to manage the window.location.reload
    // Tested from the homepage and other pages and it works as expected
    setTimeout(() => {
        if (localStorage.getItem('openCardReaderAfterLogout')) {
            openCardReaderModal()
        }

        localStorage.removeItem('openCardReaderAfterLogout')
    }, 2000)
})
</script>
